<template>
  <div class="relative flex items-stretch h-full">
    <BaseScrollbar
      :suppress-scroll-x="false"
      :wheel-propagation="true"
      class="table-scrollbar">
      <table :class="[tableClasses, { 'w-full': !list }]">
        <slot></slot>
      </table>
    </BaseScrollbar>

    <slot name="left" />
  </div>
</template>
<script lang="ts" setup>
import { provide } from 'vue';
import { useTableClasses } from '@/components/Table/composables/useTableClasses';
import { BaseScrollbar } from '@/index';

const props = defineProps({
  striped: {
    type: Boolean,
    default: false,
  },
  stripedColumns: {
    type: Boolean,
    default: false,
  },
  list: {
    type: Boolean,
    default: false,
  },
  leftCol: {
    type: Boolean,
    default: false,
  },
  hoverable: {
    type: Boolean,
    default: false,
  },
});

const { tableClasses } = useTableClasses(props.list ? 'list' : '');
provide('striped', props.striped);
provide('hoverable', props.hoverable);
provide('stripedColumns', props.stripedColumns);
provide('list', props.list);
provide('float', props.leftCol);
</script>

<style>
*[dir='rtl'] .table-scrollbar.ps.ps--active-y {
  margin-right: 0px;
}
</style>
