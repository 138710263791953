<template>
  <p v-if="title" class="text-right font-simplerBold mt-5">{{ title }}</p>
  <tr :class="tableRowClasses">
    <slot></slot>
  </tr>
</template>
<script lang="ts" setup>
import { useTableRowClasses } from '@/components/Table/composables/useTableRowClasses';

defineProps({
  title: {
    type: String,
    default: '',
  },
});
const { tableRowClasses } = useTableRowClasses();
</script>
