import { computed, inject } from 'vue';
import type { Ref } from 'vue';
import classNames from 'classnames';

const baseClasses =
  'bg-white first:font-medium text-gray-900 first:dark:text-white text-right';
const stripedCellClasses =
  'even:bg-gray-white even:dark:bg-gray-900 odd:dark:bg-gray-800 odd:bg-gray-50';
const listCellClasses =
  'px-0 border border-secondary-300 [&:not(:last-child)]:border-l-0 [&:not(:last-child):not(:first-child)]:border-r-0 [&:last-child]:border-r-0';
const floatCellClasses = 'px-0';

export function useTableCellClasses(): { tableCellClasses: Ref<string> } {
  const isColumnsStriped = inject('stripedColumns');
  const isTableList = inject('list');
  const isTableFloat = inject('float');

  const tableCellClasses = computed(() => {
    return classNames(baseClasses, {
      [stripedCellClasses]: isColumnsStriped,
      [listCellClasses]: isTableList,
      [floatCellClasses]: isTableFloat,
    });
  });

  return {
    tableCellClasses,
  };
}
