import { computed } from 'vue';
import type { Ref } from 'vue';
import classNames from 'classnames';

const baseClasses =
  'table-fixed text-sm text-left text-secondary-500 dark:text-secondary-400';
const listTableClasses =
  'border-separate border-spacing-2 -ms-[0.5rem] -mt-[0.5rem]';
const floatTableClasses = 'h-full bg-white shadow-right-side';

export function useTableClasses(type): { tableClasses: Ref<string> } {
  const tableClasses = computed(() => {
    return classNames(baseClasses, {
      [listTableClasses]: type == 'list',
      [floatTableClasses]: type == 'float',
    });
  });

  return {
    tableClasses,
  };
}
