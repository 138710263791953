import { computed, inject } from 'vue';
import type { Ref } from 'vue';
import classNames from 'classnames';

const baseClasses = 'border-collapse';
const stripedClasses =
  'odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700';
const hoverableClasses = 'hover:bg-gray-50 dark:hover:bg-gray-600';
const listclasses =
  'dark:bg-gray-800 [&:not(:last-child)]:dark:border-gray-700';
const floatClasses = '[&:not(:last-child)]:border-b-14 border-white';
export function useTableRowClasses(): { tableRowClasses: Ref<string> } {
  const isStriped = inject('striped');
  const isListTable = inject('list');
  const isFloatTable = inject('float');
  const isHoverable = inject('hoverable');

  const tableRowClasses = computed(() => {
    return classNames(baseClasses, {
      [stripedClasses]: isStriped,
      [hoverableClasses]: isHoverable,
      [listclasses]: isListTable,
      [floatClasses]: isFloatTable,
    });
  });

  return {
    tableRowClasses,
  };
}
